import { fetchRequest } from './apiHelper';
export const submitForm = async (apiServer, scoreType, values, setResult, queryResult) => {
  setResult({ status: 'pending' });

  const url = `${apiServer}/mortality_calculator/calculate_euro_${scoreType.toLowerCase()}.json`;

  try {
    const data = await fetchRequest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    if (data.status === 'ok') {
      queryResult(data.job_id, apiServer, setResult);
    } else {
      setResult({ status: 'error', result: data.error });
    }
  } catch (err) {
    setResult({ status: 'error', result: err.message });
  }
};

