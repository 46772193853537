import React from 'react';

const ResultScoreDiagrams = ({ percentileUrl, outcomesUrl }) => {
  return (
    <>
      <canvas
        id="risk-canvas1"
        className="percentile-rank"
        style={{ backgroundImage: `url(${percentileUrl})` }}
      />
      <canvas
        id="risk-canvas2"
        className="percentile-rank"
        style={{ backgroundImage: `url(${outcomesUrl})` }}
      />
    </>
  );
};

export default ResultScoreDiagrams;
