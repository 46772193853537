import React, { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import tr_parameters from './data/tr_parameters';
import smr_parameters from './data/smr_parameters';

import { drawResult } from './helpers/drawing';
import { submitForm } from './helpers/submitForm';
import { queryResult } from './helpers/queryResult';

import FormRowRenderer from '././components/FormRowRenderer';
import FormSubmitButton from '././components/FormSubmitButton';
import ResultStatusCard from '././components/ResultStatusCard';
import FormSelectedValues from '././components/FormSelectedValues';
import ResultScoreDiagrams from '././components/ResultScoreDiagrams';

const EuroSmrScoreWidget = ({
  apiServer,
  scoreType,
  percentileUrl,
  outcomesUrl,
}) => {
  const [values, setValues] = useState({});
  const [result, setResult] = useState({});

  useEffect(() => {
    if (result?.status === 'completed') drawResult(result);
  }, [result]);

  const handleSubmit = () =>
    submitForm(apiServer, scoreType, values, setResult, queryResult);

  const updateValue = (attribute, newValue) => {
    const newValues = { ...values };
    newValues[attribute] = newValue;
    if (newValue === 'n/a') delete newValues[attribute];
    setValues(newValues);
  };

  const modelParameters = scoreType === 'SMR' ? smr_parameters : tr_parameters;

  return (
    <div className="euro-smr-score-widget">
      <form>
        <Row>
          <Col md={7}>
            {modelParameters.map((field, index) => (
              <FormRowRenderer
                key={index}
                field={field}
                index={index}
                values={values}
                updateValue={updateValue}
              />
            ))}
          </Col>
          <Col md={5} className="flex-column-center">
            <FormSelectedValues values={values} scoreType={scoreType} />
            <FormSubmitButton
              handleSubmit={handleSubmit}
              values={values}
              result={result}
              scoreType={scoreType}
            />
            {result.status && (
              <ResultStatusCard result={result} scoreType={scoreType} />
            )}
            {result.status === 'completed' && (
              <ResultScoreDiagrams
                percentileUrl={percentileUrl}
                outcomesUrl={outcomesUrl}
              />
            )}
          </Col>
        </Row>
      </form>
    </div>
  );
};
export default EuroSmrScoreWidget;
