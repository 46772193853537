import { fetchRequest } from './apiHelper';
export const queryResult = (job_id, apiServer, setResult) => {
  const url = `${apiServer}/smr_results/${job_id}.json`;

  fetchRequest(url)
    .then((data) => {
      switch (data.status) {
        case 'pending':
          setResult(data);
          setTimeout(() => queryResult(job_id, apiServer, setResult), 1000);
          break;
        case 'completed':
          setResult({
            ...data,
            result: parseFloat(data.result.replace('[1] ', '')),
          });
          break;
        default:
          setResult(data);
      }
    })
    .catch((err) => {
      setResult({ status: 'error', result: err.message });
    });
};
