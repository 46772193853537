import React from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';

export default function Tooltip({ text }) {
  const popoverID = useRef(`threeD-${Math.random().toString(36).substr(2, 9)}`);

  return (
    <div className="tooltip-partial">
      <button
        type="button"
        aria-label="Tooltip"
        aria-describedby={popoverID.current}
        className={'icon fas fa-question-circle'}
      ></button>
      <span
        role="tooltip"
        className={'platzhalter-fuer-nicole'}
        id={popoverID.current}
      >
        {text}
      </span>
    </div>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};
