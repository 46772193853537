export default [
  { id: 'Age', label: 'Age [years]', min: 50, max: 90, step: 1, value: 50 },
  {
    id: 'NYHA',
    label: 'NYHA functional class',
    min: 1,
    max: 4,
    choices: [
      {
        label: 'NYHA I',
        value: 1,
      },
      {
        label: 'NYHA II',
        value: 2,
      },
      {
        label: 'NYHA III',
        value: 3,
      },
      {
        label: 'NYHA IV',
        value: 4,
      },
    ],
    value: 1,
  },
  { id: 'BMI', label: 'BMI kg/m', min: 15, max: 40, step: 0.1, value: 15 },
  { id: 'COPD', label: 'COPD', type: 'radio', min: 0, max: 1, step: 1 },
  {
    id: 'Diabetes',
    label: 'Diabetes mellitus',
    type: 'radio',
    min: 0,
    max: 1,
    step: 1,
  },
  {
    id: 'Triple_GDMT',
    label: 'Triple GDMT',
    type: 'radio',
    min: 0,
    max: 1,
    step: 1,
  },
  {
    id: 'NTproBNP_pre',
    label: 'NT-proBNP [pg/mL]',
    min: 0,
    max: 40000,
    step: 1,
    value: 0,
  },
  {
    id: 'GFR_pre',
    label: 'eGFR [mL/min]',
    min: 0,
    max: 120,
    step: 1,
    value: 0,
  },
  {
    id: 'Hemoglobin_pre',
    label: 'Hemoglobin [g/dL]',
    min: 5,
    max: 20,
    step: 0.1,
    value: 5,
  },

  {
    id: 'LVEF_pre',
    label: 'LVEF [%]',
    min: 10,
    max: 70,
    step: 0.1,
    value: 10,
  },

  {
    id: 'LVEDV_pre',
    label: 'LVEDV [mL]',
    min: 60,
    max: 350,
    step: 1,
    value: 60,
  },

  {
    id: 'MV_EROA_pre',
    label: 'MV EROA [cm2]',
    min: 0,
    max: 1,
    step: 0.1,
    value: 0,
  },
  {
    id: 'LA_volume_pre',
    label: 'LA volume [mL]',
    min: 20,
    max: 300,
    step: 1,
    value: 20,
  },
  {
    id: 'sPAP_pre',
    label: 'sPAP [mmHg]',
    min: 20,
    max: 100,
    step: 1,
    value: 20,
  },

  {
    id: 'RV_Mitte_pre',
    label: 'Right midventricular diameter [mm]',
    min: 10,
    max: 60,
    step: 0.1,
    value: 10,
  },

  {
    id: 'TAPSE_pre',
    label: 'TAPSE [mm]',
    min: 5,
    max: 30,
    step: 1,
    value: 5,
  },
  {
    id: 'TR_pre',
    label: 'TR severity',
    min: 1,
    max: 4,
    step: 1,
    choices: [
      {
        label: 'I/IV°',
        value: 1,
      },
      {
        label: 'II/IV°',
        value: 2,
      },
      {
        label: 'III/IV°',
        value: 3,
      },
      {
        label: 'IV/IV°',
        value: 4,
      },
    ],
  },
  {
    id: 'RA_size_pre',
    label: 'RA area [cm]',
    min: 10,
    max: 50,
    step: 1,
    value: 10,
  },
];
