import React from 'react';
const RadioGroup = ({
  id,
  options,
  value,
  onChange,
  layout = 'row',
  className = '',
}) => {
  return (
    <div
      className={`form-check ${className} ${
        layout === 'column' ? 'column-layout' : 'row-layout'
      }`}
    >
      {options.map((option) => (
        <label
          key={option.value}
          className="rowflex"
          htmlFor={`${id}-${option.value}`}
        >
          <input
            type="radio"
            name={id}
            id={`${id}-${option.value}`}
            value={option.value}
            checked={value === option.value}
            onChange={(e) => onChange(e.target.value)}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
